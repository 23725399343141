import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Protupožarna vrata - Proizvodi | Protupožarna zaštita',
  description: 'Protupožarna vrata najčešći su i najprepoznatljiviji element za zaštitu od požara na građevini. Susrećemo ih u raznim oblicima (jednokrilna, dvokrilna, klizna, djelomično ostakljena, potpuno ostakljena i slično). Primjenom raznih vrsta materijala i kombiniranjem s protupožarnim staklom uspijevaju se pomiriti zahtjevi za estetskim oblikovanjem i zaštitom od požara. Ispitivanja svih tipova protupožarnih vrata provode se prema jasno definiranim standardima.',
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarna-vrata/`, name: 'Protupožarna vrata'},
      ]}
    />
    <Hero
      description="Protupožarna vrata najčešći su i najprepoznatljiviji element za zaštitu od požara na građevini. Susrećemo ih u raznim oblicima (jednokrilna, dvokrilna, klizna, djelomično ostakljena, potpuno ostakljena i slično). Primjenom raznih vrsta materijala i kombiniranjem s protupožarnim staklom uspijevaju se pomiriti zahtjevi za estetskim oblikovanjem i zaštitom od požara. Ispitivanja svih tipova protupožarnih vrata provode se prema jasno definiranim standardima."
      slim
      subtitle="Protupožarna vrata"
      title="Proizvodi"
    />
    <Products activeCategory="protupozarna-vrata" />
  </Page>
)

export default Category
